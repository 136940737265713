<template>
    <div>
        <GMapMap :center="center" :zoom="15" map-type-id="terrain" class="map">
            <GMapMarker
                :key="marker.id"
                v-for="marker in markers"
                :position="marker.position"
            />
        </GMapMap>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const center = ref({ lat: 49.22398479918161, lng: 18.7419322698903 })
const markers = ref([
    {
        id: 1,
        position: {
            lat: 49.223950055196816,
            lng: 18.741872902854368,
        },
    },
])
</script>

<style lang="scss" scoped>
.map {
    width: 50em;
    height: 30em;
}
@media (max-width: 1230px) {
    .map {
        width: 45em;
        height: 30em;
    }
}

@media (max-width: 1200px) {
    .map {
        width: 100%;
        height: 30em;
        margin-top: 1.5em;
    }
}
</style>
