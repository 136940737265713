<template>
    <div @click="switching" class="telephones-wraper">
        <slot name="data"></slot>
    </div>

    <div @click="wraperReady = false" v-if="showing" class="bgr">
        <div class="fixed-bgr"></div>
    </div>

    <div
        v-if="showing"
        @focusout="wraperReady = false"
        tabindex="0"
        ref="wraper"
        class="absolute"
        v-touch:swipe.left="hideWithSwipe"
    >
        <Transition name="fade" @after-leave="showing = false">
            <div v-if="wraperReady" class="advanced-container">
                <div class="close">
                    <font-awesome-icon
                        @click="wraperReady = false"
                        class="icon"
                        icon="fa-solid fa-xmark"
                    />
                </div>
                <slot :name="content"></slot>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ref, onMounted, onUpdated, Transition, defineProps } from 'vue'

const props = defineProps(['data', 'product'])

const showing = ref(false)
const wraper = ref(null)
const wraperReady = ref(false)
const content = ref('')
const ids = ref([1, 2, 3])
const defaultScrollPosition = ref('')

const hideWithSwipe = () => {
    wraperReady.value = false
}

onMounted(() => {
    ids.value.map((id) => {
        if (id === props.data) content.value = props.product
    })
})

onUpdated(() => {
    defaultScrollPosition.value = +window.scrollY

    wraper.value ? (wraperReady.value = true) : (wraperReady.value = false)
})

const switching = (e) => {
    showing.value = !showing.value
}
</script>

<style lang="scss" scoped>
@import '../../../sass/main/variables';

.bgr {
    position: absolute;
    top: 0;
    left: 0;
}

.fixed-bgr {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(black, 0);
}
.absolute {
    position: absolute;
    left: 50%;
    margin-left: -40vw;
    height: 100%;
}

.close {
    position: relative;
}

.icon {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;

    color: darken($master-color-noalpha, 10%);
    font-size: 2em;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.icon:hover {
    transform: scale(1.1);
    color: $master-color;
}
.advanced-container {
    position: fixed;
    top: 12vh;
    z-index: 100;
    padding: 1.5em;

    width: 80vw;
    min-height: 55vh;
    max-height: 85vh;

    background-color: rgba(white, 0.8);
    box-shadow: 0 0 8px rgba(black, 0.3);
    backdrop-filter: blur(10px);

    overflow: auto;
}

.fade-enter-active {
    transition: opacity 120ms ease-in-out, transform 150ms ease-in-out;
}
.fade-leave-active {
    transition: opacity 90ms ease-in-out, transform 100ms ease-in-out;
}

.fade-enter-from {
    transform: translateX(-100px);
}
.fade-leave-to {
    opacity: 0;
    transform: translateX(-100px);
}

.fade-bgr-enter-active,
.fade-bgr-leave-active {
    transition: opacity 90ms ease-in;
}

.fade-bgr-enter-from,
.fade-bgr-leave-to {
    opacity: 0;
}

@media (max-width: 960px) {
    .advanced-container {
        top: 10vh;
        width: 90wv;
        max-height: 85vh;
    }
}

@media (max-width: 890px) {
    .absolute {
        position: absolute;
        left: 0;
        margin-left: 0;
        overflow: hidden;
    }
    .advanced-container {
        top: 0;

        width: 95vw;
        height: 100vh;

        min-height: unset;
        max-height: unset;

        overflow: auto;
    }
}

@media (max-width: 560px) {
    .advanced-container {
        padding: 1em;
    }

    .icon {
        transform: scale(0.9);
    }
}
</style>
