/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '../bootstrap.js';
import { createApp } from 'vue';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {fas} from "@fortawesome/free-solid-svg-icons";
library.add(fas)


//Google Maps
import  VueGoogleMaps from '@fawmi/vue-google-maps'

//Vue3 touch events
import Vue3TouchEvents from "vue3-touch-events";


const app = createApp({});

import NavigationBar from './components/NavigationBar.vue';
app.component('navigation-bar', NavigationBar);

import NavigationItem from './components/NavigationItem.vue';
app.component('navigation-item', NavigationItem);

import FlashMessage from './components/FlashMessage.vue';
app.component('flash-message', FlashMessage);

import CameraOrder from './components/CameraOrder.vue';
app.component('camera-order', CameraOrder);

import ConditionPart from './components/ConditionPart.vue';
app.component('condition-part', ConditionPart);

import FooterItem from './components/FooterItem.vue';
app.component('footer-item', FooterItem);

import NavigationMenu from './components/NavigationMenu.vue';
app.component('navigation-menu', NavigationMenu);

import FaqIcon from './components/FaqIcon.vue';
app.component('faq-icon', FaqIcon);

import FaqAnswer from './components/FaqAnswer.vue';
app.component('faq-answer', FaqAnswer);

import MapsComp from './components/MapsComp.vue';
app.component('maps-comp', MapsComp);

import ProductInfo from './components/ProductInfo.vue';
app.component('product-info', ProductInfo);

import NavigationSubMenu from './components/NavigationSubMenu.vue';
app.component('navigation-sub-menu', NavigationSubMenu);

import BrandImage from './components/BrandImage.vue';
app.component('brand-image', BrandImage);



app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCFwAMtfIKa-z5p8KWfGfTOjocTDRzNIHM',
    },
})
.use(Vue3TouchEvents)
.mount('#app');
