<template>
    <div class="field mauto">
        <label class="label"> {{ props.label }}</label>

        <div class="input-wraper">
            <textarea
                class="input-style"
                type="text"
                :class="{ focused: isFocus }"
                @focus="isFocus = true"
                @blur="isFocus = false"
                v-model="data.note"
            ></textarea>
            <span>
                <font-awesome-icon
                    class="input-icon"
                    :class="{ focused: isFocus }"
                    :icon="('fa-solid', props.specify)"
                />
            </span>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue'
import { data } from '../formStore'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { note } from '../formStore'
import { nextTick } from 'process'

const props = defineProps(['specify', 'label'])

const isFocus = ref(false)
const height = ref('')

onMounted(() => {
    nextTick(() => (height.value = note.height + 'px'))
})
</script>

<style lang="scss" scoped>
@import '../../../sass/main/variables';

.mauto {
    margin-top: auto;
}
.input-style {
    margin-bottom: 1rem;
    width: 40rem;
    height: v-bind('height');
    outline: none;
    font-size: 1.2rem;
    padding: 0.8rem 3rem;
    color: darken($master-color, 20%);
    opacity: 0.5;
    border: 1px solid $master-color;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px darken($master-color, 20%);
    transition: opacity 200ms ease-in-out;
}
.input-wraper {
    position: relative;

    span {
        position: absolute;
        left: 12px;
        top: 12px;
    }
    textarea {
        resize: none;
    }
}

.label {
    font-weight: 700;
}
.phone-number[type='number'] {
    appearance: textfield;
}

.phone-number::-webkit-outer-spin-button,
.phone-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input-icon {
    transform: scale(1.5);
    opacity: 0.5;
    color: darken($master-color, 30%);
}

.focused {
    opacity: 1;
}

@media (max-width: 795px) {
    .input-style {
        width: 100%;
    }

    .label {
        font-size: 0.9em;
    }
}
</style>
