<template>
    <div class="field">
        <div class="label-wraper">
            <label class="label"> {{ props.label }}</label>
            <span class="failed-text"> {{ failedControl }} </span>
        </div>

        <div class="input-wraper">
            <input
                class="input-style"
                type="text"
                :class="{ focused: isFocus, danger: props.isDanger }"
                @focus="isFocus = true"
                @blur="isFocus = false"
                @keydown="removeBorder"
                v-model="text"
            />
            <span>
                <font-awesome-icon
                    class="input-icon"
                    :class="{ focused: isFocus }"
                    :icon="('fa-solid', props.specify)"
                />
            </span>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, computed, defineEmits } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { data, failed } from '../formStore'

const props = defineProps(['specify', 'label', 'field', 'isDanger'])
const emits = defineEmits(['borderOff'])
const isFocus = ref(false)

const removeBorder = (e) => {
    if (props.isDanger) {
        if (
            e.key === 'Backspace' ||
            e.key === 'Delete' ||
            text.value.length >= 3
        ) {
            emits('borderOff')
            if (props.field === 'name') failed.errorName('')
            if (props.field === 'email') failed.errorEmail('')
            if (props.field === 'mobil') failed.errorMobil('')
            if (props.field === 'count') failed.errorCount('')
        }
    }
}

const text = computed({
    // getter
    get() {
        if (props.field === 'name') return data.name
        if (props.field === 'mobil') return data.mobil
        if (props.field === 'email') return data.email
        if (props.field === 'count') return data.count
    },
    // setter
    set(newValue) {
        if (props.field === 'name') data.setName(newValue)
        if (props.field === 'email') data.setEmail(newValue)
        if (props.field === 'mobil') data.setMobil(newValue)
        if (props.field === 'count') data.setCount(newValue)
    },
})

const failedControl = computed({
    get() {
        if (props.field === 'name') return failed.name
        if (props.field === 'email') return failed.email
        if (props.field === 'mobil') return failed.mobil
        if (props.field === 'count') return failed.count
    },
})
</script>

<style lang="scss" scoped>
@import '../../../sass/main/variables';
.input-style {
    margin-bottom: 1rem;
    outline: none;
    font-size: 1.3rem;
    padding: 1rem 3rem;
    height: 2.5rem;
    width: 40rem;
    color: darken($master-color, 20%);
    opacity: 0.5;
    border: 1px solid $master-color;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px darken($master-color, 20%);
    transition: opacity 200ms ease-in-out;
}

.input-wraper {
    position: relative;
    span {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(12px, -15px);
    }
}

.label-wraper {
    display: flex;
}

.failed-text {
    margin-left: auto;
    color: red;
    padding-right: 0.2rem;
}

.label {
    font-weight: 700;
}
.phone-number[type='number'] {
    appearance: textfield;
}

.phone-number::-webkit-outer-spin-button,
.phone-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input-icon {
    transform: scale(1.5);
    opacity: 0.5;
    color: darken($master-color, 30%);
}

.focused {
    opacity: 1;
}
.danger {
    border: 1px solid red;
    box-shadow: 0 0 2px darken(red, 20%);
}

@media (max-width: 795px) {
    .input-style {
        width: 100%;
        font-size: 1.2rem;
        padding: 1.2rem 3rem;
    }

    .input-wraper {
        span {
            transform: translate(12px, -16px);
        }
    }

    .label {
        font-size: 0.9em;
    }
}
</style>
