<template>
    <li class="list">
        <h2 @click="visibleON" class="name">
            <slot name="header"></slot>
            <font-awesome-icon
                class="plus-ico"
                icon="fa-solid fa-circle-plus"
            />
        </h2>

        <Transition name="slide-fade">
            <p v-if="showing">
                <slot name="content"></slot>
            </p>
        </Transition>
    </li>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ref } from 'vue'

const showing = ref(false)

const visibleON = () => {
    showing.value = !showing.value
}
</script>

<style lang="scss" scoped>
@import '../../../sass/main/variables';
.list {
    list-style: none;

    h2 {
        color: darken($master-color, 15%);
    }
}

.name {
    position: relative;
    cursor: pointer;
}

.plus-ico {
    position: absolute;
    top: 1px;
    left: -30px;
    cursor: pointer;
}

.slide-fade-enter-active {
    transition: all 0.15s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.15s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-50px);
    opacity: 0;
}
</style>
