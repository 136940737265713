<template>
    <Transition name="slide-fade">
        <div
            class="background"
            :class="{ 'is-still': transparency }"
            v-if="isShow"
        ></div>
    </Transition>
</template>

<script>
export default {
    props: {
        nonstop: {
            type: Boolean,
        },
    },

    data() {
        return {
            isShow: false,
            transparency: false,
            pos: 0,
            scrollValue: 100,
        }
    },

    methods: {
        comparePos() {
            if (!this.nonstop) {
                if (window.scrollY >= this.scrollValue) {
                    this.isShow = true
                } else this.isShow = false
            } else {
                if (window.scrollY >= this.scrollValue) {
                    this.transparency = false
                } else this.transparency = true
            }
        },
    },

    created() {
        window.addEventListener('scroll', this.comparePos)
        if (this.nonstop) {
            this.transparency = this.nonstop
            this.isShow = true
        }
    },

    destroyed() {
        window.removeEventListener('scroll', this.comparePos)
    },
}
</script>

<style lang="scss" scoped>
.background {
    background: rgba(78, 125, 166, 1);
    background: linear-gradient(
        90deg,
        rgba(78, 125, 166, 1) 0%,
        rgba(94, 131, 163, 1) 82%
    );
    position: absolute;
    width: 100%;
    height: 85px;
    z-index: 10;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    box-shadow: 0 2px 30px rgba(black, 0.6);
}

.is-still {
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
}

.slide-fade-enter-active {
    transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}

@media (max-width: 560px) {
    .background {
        height: 70px;
    }

    .background-filter {
        height: 70px;
    }
}
</style>
