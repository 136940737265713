<template>
    <div class="camera-order-form">
        <form action="">
            <div class="master-camera-wraper">
                <div ref="inputWraper" class="basic-info-form">
                    <FormInput
                        field="name"
                        label="Meno a priezvisko"
                        specify="fa-user"
                        :isDanger="editDangerName"
                        @borderOff="editDangerName = false"
                    />
                    <FormInput
                        field="email"
                        label="Email"
                        specify="envelope"
                        :isDanger="editDangerEmail"
                        @borderOff="editDangerEmail = false"
                    />
                    <FormInput
                        field="mobil"
                        label="Telefónne číslo"
                        specify="fa-mobile-screen"
                        :isDanger="editDangerMobil"
                        @borderOff="editDangerMobil = false"
                    />
                </div>

                <div class="basic-info-form">
                    <FormInput
                        field="count"
                        label="Odhadovaný počet kamier"
                        specify="fa-video"
                        :isDanger="editDangerCount"
                        @borderOff="editDangerCount = false"
                        v-if="props.camera"
                    />
                    <NoteInput label="Poznámka" specify="fa-clipboard" />
                </div>
            </div>

            <div class="button-wraper">
                <button class="button" @click.prevent="submit">
                    Odoslať formulár
                </button>
                <label for="suhlas"
                    >Odoslaním formuláru súhlasím s podmienkami
                    <a href="/ochrana-osobnych-udajov"
                        ><strong>ochrany osobných údajov</strong></a
                    ></label
                >
            </div>
        </form>
        <FlashMessage />
    </div>
</template>

<script setup>
import { data, failed, message, note } from '../formStore'
import { ref, defineProps, onMounted } from 'vue'
import FlashMessage from './FlashMessage.vue'
import FormInput from './FormInput.vue'
import NoteInput from './NoteInput.vue'
import axios from 'axios'

const props = defineProps(['type', 'camera'])

const editDangerName = ref(false)
const editDangerEmail = ref(false)
const editDangerMobil = ref(false)
const editDangerCount = ref(false)

const inputWraper = ref(null)

const object = ref({
    name: '',
    email: '',
    mobil: '',
    note: '',
    count: '',
    type: props.type,
})

const badData = ref({
    name: [''],
    email: [''],
    mobil: [''],
    note: [''],
    count: [''],
})

const submit = () => {
    object.value.name = data.name
    object.value.email = data.email
    object.value.mobil = data.mobil
    object.value.note = data.note
    object.value.count = data.count

    axios
        .post('store', object.value)
        .then((response) => {
            message.visible(
                'Formulár bol úspešne odoslaný. V krátkom čase Vás bude kontaktovať technik pre konzultáciu vašej požiadavky.'
            )
            data.setName()
            data.setEmail()
            data.setMobil()
            data.setNote()
            data.setCount()
        })
        .catch((errors) => {
            badData.value = errors.response.data.errors

            if (badData.value.name) {
                failed.errorName('Zadaj meno a priezvisko.')
                editDangerName.value = true
            }
            if (badData.value.email) {
                failed.errorEmail('Emailová adresa nie je správna.')
                editDangerEmail.value = true
            }
            if (badData.value.mobil) {
                failed.errorMobil('Zadaj telefónne číslo.')
                editDangerMobil.value = true
            }
            if (badData.value.count) {
                failed.errorCount('Zadaj číslo.')
                editDangerCount.value = true
            }
        })
}

onMounted(() => {
    if (!props.camera) {
        note.setHeight(inputWraper.value.offsetHeight - 38)
    } else note.setHeight(164 - 26.5 - 14)
})
</script>

<style lang="scss" scoped>
@import '../../../sass/main/variables';

.button-wraper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    padding: 2rem;

    label {
        text-align: center;
    }
}

.button {
    cursor: pointer;
    padding: 1rem;
    background-color: $master-color;
    color: white;
    border-radius: 0.3rem;
    border: 1px solid white;
    font-size: 1.1rem;
    font-weight: 700;
    transition: all 150ms ease-in-out;
}

.button:hover {
    background-color: lighten($master-color, 10%);
    text-shadow: 0 0 3px darken($master-color, 20%);
    transition: all 150ms ease-in-out;
    transform: scale(1.02);
}

.camera-order-form {
    padding-top: 2em;
}

.master-camera-wraper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
}

.basic-info-form {
    display: flex;
    flex-direction: column;
}

.inactive {
    background-color: white;
    border: 1px solid $master-color;
    color: lighten($master-color, 20%);
    cursor: not-allowed;
    padding: 1rem;
    border-radius: 0.3rem;
    font-size: 1.1rem;
    font-weight: 700;
    transition: all 150ms ease-in-out;
}

.camera-input[type='number'] {
    appearance: textfield;
}

.camera-input::-webkit-outer-spin-button,
.camera-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media (max-width: 960px) {
    .button-wraper {
        flex-direction: column;
        align-items: center;
    }

    .send-form-radio {
        text-align: center;
    }
}

@media (max-width: 795px) {
    .basic-info-form {
        flex-basis: 100%;
    }
}
</style>
