<template>
    <Transition name="slide-fade">
        <div v-if="message.show" class="notification">
            <div @click="message.hide">
                <font-awesome-icon icon="fa-solid fa-xmark" class="cancel" />
            </div>
            <p>{{ message.text }}</p>
            <!-- <button @click="message.hide"></button> -->
        </div>
    </Transition>
</template>

<script>
import { message } from '../formStore'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            message,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../../sass/main/variables';
.notification {
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;

    margin: 3em;
    padding: 1em 3rem;
    background-color: green;
    color: white;
    box-shadow: 0 0 8px $master-color;
    border-radius: 0.5rem;

    p {
        font-weight: 700;
        font-size: 1em;
    }
}

.cancel {
    position: absolute;
    z-index: 20;
    right: 0;
    top: 0;
    margin: 0.5rem 0.8rem;
    transition: transform 100ms ease-in-out;
    cursor: pointer;
}

.cancel:hover {
    transform: scale(1.15);
}

.slide-fade-enter-active {
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.slide-fade-leave-active {
    transition: all 0.2s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(350px);
    opacity: 0;
}

@media (max-width: 795px) {
    .notification {
        left: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        border-radius: 0;
        padding: 2rem 2rem;

        p {
            margin: 0;
        }
    }

    .slide-fade-enter-active {
        opacity: 1;
        transition: all 0.2s ease-in-out;
    }

    .slide-fade-leave-active {
        transition: all 0.2s ease-in-out;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateY(5em);
        opacity: 0;
    }
}
</style>
