import { faL } from "@fortawesome/free-solid-svg-icons";
import { reactive } from "vue";

export const data = reactive({
    name: '',
    email: '',
    mobil: '',
    note: '',
    count:'',
    
    setName(data = '') {
        this.name = data
    },
    
    setEmail(data = '') {
        this.email = data
    },
    
    setMobil(data = '') {
        this.mobil = data
    },
    
    setNote(data = '') {
        this.note = data
    },
    setCount(data = '') {
        this.count = data
    }
})

export const failed = reactive({
    name: '',
    email: '',
    mobil: '',
    note: '',
    count:'',
    
    errorName(error) {
        this.name = error
    },
    
    errorEmail(error) {
        this.email = error
    },
    
    errorMobil(error) {
        this.mobil = error
    },
    
    errorNote(error) {
        this.note = error
    },
    errorCount(error) {
        this.count = error
    }
})

// FlashComponent store
export const message = reactive({
    show: false,
    text: 'default',
  
    visible(text) {
      this.text = text
      this.show = true
      setTimeout(() => {
          this.show = false
      }, 7000);
    },
    
    hide() {
      this.show = false
    }  
  })
  
  
  // Background in resposive menu
  export const menu = reactive({
    show: false,
    
    visible() {
        this.show = true
    },
    
    hide() {
        this.show = false
    }
  })
  
  
  // NoteInput height without camera count
  export const note = reactive({
    height: '',
    
    setHeight(number) {
        this.height = number
    }
  })
  
  // Showing offer in menu
  
  export const menuOffer  = reactive({
    visibility: false,
    
    showMe() {
        this.visibility = true
    },
    
    hideMe() {
        this.visibility = false
    }
    
  })