<template>
    <li>
        <a ref="ponuka" class="nav-link" :href="url">{{ text }}</a>
    </li>
</template>

<script>
import { menuOffer } from '../formStore'

export default {
    props: ['text', 'link', 'noofer'],

    data() {
        return {
            hover: false,
            ponuka: false,
            url: '',
            menuOffer,
        }
    },

    methods: {
        isHover() {
            this.hover = true
        },

        normal() {
            this.hover = false
        },
    },

    mounted() {
        this.url = this.link

        if (this.url === 'ponuka') {
            if (this.noofer) {
                this.$refs.ponuka.classList.add('hide-offer')
            } else {
                this.$refs.ponuka.addEventListener('click', (e) => {
                    e.preventDefault()
                    menuOffer.showMe()
                })
            }
        }
    },
}
</script>

<style lang="scss" scoped>
li {
    list-style: none;
    margin-left: 2em;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
    }

    a {
        text-decoration: none;
        font-size: 1.2em;
        font-weight: 700;
        text-shadow: 0 0 1px rgba(#000000, 0.5);
    }
}

@media (max-width: 795px) {
    li {
        margin-left: 0;
    }
}
</style>
