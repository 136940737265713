<template>
    <div class="menu-wraper">
        <div>
            <slot name="logo"></slot>
        </div>
        <font-awesome-icon
            @click="visible"
            class="menu"
            icon="fa-solid fa-bars"
        />
    </div>

    <Transition name="fade">
        <div @click="close" v-if="menu.show" class="menu-bgr"></div>
    </Transition>

    <TransitionGroup name="slide-fade" @after-enter="onAfterEnter">
        <div v-if="menu.show" class="menu-window" ref="menuWindow">
            <ul ref="menuBox" class="menuBox">
                <slot name="menu-logo"></slot>
                <slot v-if="!menuOffer.visibility" name="items"></slot>

                <NavigationSubMenu
                    v-if="menuOffer.visibility"
                    v-for="item in subMenu"
                    :key="item.id"
                    :text="item.name"
                    :url="item.url"
                />
            </ul>

            <div @click="close" class="close-btn" ref="markX">
                <font-awesome-icon
                    class="cancel-ico"
                    icon="fa-solid fa-xmark"
                />
            </div>
        </div>
    </TransitionGroup>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import NavigationSubMenu from './NavigationSubMenu.vue'
import { menu, menuOffer } from '../formStore'
import { ref } from 'vue'

const visibleState = ref('hidden')
const menuBox = ref(null)
const menuWindow = ref(null)
const markX = ref(null)
const actualPosition = ref('')
const subMenu = ref([
    { name: 'Kamerový systém', url: '/kamerovy-system' },
    { name: 'Zabezpečovací systém', url: '/zabezpecovacie-systemy' },
    { name: 'Elektrický vrátnik', url: '/videovratniky' },
    { name: 'Smart Home', url: '/smart-home' },
])

const onAfterEnter = () => {
    visibleState.value = 'visible'
    menuBox.value.classList.add('menuBoxVisible')
    markX.value.classList.add('menuBoxVisible')
    console.log(menuWindow.value.clientHeight)
}

const visible = () => {
    document.addEventListener('scroll', position, { passive: true })
    actualPosition.value = window.scrollY

    menu.show ? menu.hide() : menu.visible()
}

const close = () => {
    document.removeEventListener('scroll', position, { passive: true })

    setTimeout(() => {
        menuOffer.hideMe()
    }, 100)
    menuBox.value.classList.remove('menuBoxVisible')
    markX.value.classList.remove('menuBoxVisible')
    setTimeout(() => {
        menu.hide()
    }, 70)
}

const position = () => {
    if (actualPosition.value < window.scrollY - 20) {
        close()
    }
}
</script>

<style lang="scss" scoped>
@import '../../../sass/main/variables';

.menuBox {
    visibility: v-bind(visibleState);
    text-align: left;
    opacity: 0;
    transition: opacity 70ms ease-in-out;
    list-style: none;
}

.menu {
    transform: scale(2);
    margin-left: auto;
    padding-top: 1.5em;
    padding-right: 2em;
    color: white;
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    position: relative;
    z-index: 100;

    &:hover {
        transform: scale(2.1);
    }
}

.menu-wraper {
    display: flex;
}

.menu-window {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    width: 100%;
    height: min-content;

    color: white;
    background-color: $master-color-noalpha;
    box-shadow: 0 0 8px darken($master-color, 20%);
}

.menu-bgr {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;

    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.close-btn {
    margin-top: auto;
    padding: 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    opacity: 0;
    transition: opacity 70ms ease-in-out;
    align-self: center;
}

.cancel-ico {
    height: 1.5em;
    vertical-align: -0.35em;
    margin-right: 0.5rem;
}

.menuBoxVisible {
    opacity: 1;
}

.slide-fade-enter-active {
    transition: all 180ms ease-out;
}

.slide-fade-leave-active {
    transition: all 180ms ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-60vh);
    opacity: 0.5;
}

.fade-enter-active {
    transition: all 200ms ease-out;
}

.fade-leave-active {
    transition: all 200ms ease-in;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@media (max-width: 560px) {
    .menu {
        margin-right: 0;
        padding-right: 1.2em;
        padding-top: 1.2em;
        width: 0.7em;
    }

    .close-btn {
        font-size: 1.1rem;
    }
}
</style>
