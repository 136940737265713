<template>
    <div @click="visibleON">
        <div class="header">
            <slot name="header"></slot>
            <font-awesome-icon
                v-if="!showing"
                class="icon"
                icon="fa-solid fa-angles-down"
            />
            <font-awesome-icon
                v-if="showing"
                class="icon"
                icon="fa-solid fa-angles-up"
            />
        </div>
    </div>

    <Transition name="slide-fade" @after-enter="visibleContent">
        <div v-if="showing">
            <Transition name="fade">
                <slot v-if="content" name="content"></slot>
            </Transition>
        </div>
    </Transition>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const props = defineProps(['open'])

const showing = ref(false)
const content = ref(false)

const visibleON = () => {
    content.value = false
    showing.value = !showing.value
}

const visibleContent = () => {
    content.value = true
}

const mounted = onMounted(() => {
    props.open ? (showing.value = true) : (showing.value = false)
})
</script>

<style lang="scss" scoped>
@import '../../../sass/main/variables';
.header {
    display: flex;
    align-items: baseline;
}
.header:hover {
    color: darken($master-color, 10%);
}
.icon {
    margin-left: auto;
}
.slide-fade-enter-active {
    transition: transform 100ms ease-out;
}

.slide-fade-leave-active {
    transition: transform 100ms ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-50px);
    opacity: 0;
}

.fade-enter-active {
    transition: opacity 150ms ease-out;
}

.fade-leave-active {
    transition: opacity 150ms ease-in;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
