<script setup>
import { reactive, onMounted, onBeforeUnmount, ref } from 'vue'
const brands = reactive([
    {
        showing: true,
        id: 1,
        img: 'dahua_logo.webp',
        alt: 'Kamerové systémy Dahua',
    },
    {
        showing: true,
        id: 2,
        img: 'easydoor_logo.webp',
        alt: 'Video vrátniky Easydoor',
    },
    {
        showing: true,
        id: 3,
        img: 'satel_logo.webp',
        alt: 'Zabezpečovacie systémy Satel',
    },
    {
        showing: true,
        id: 4,
        img: 'taphome_logo.webp',
        alt: 'Inteligentná domácnosť Taphome',
    },
    {
        showing: true,
        id: 5,
        img: 'avigilon_logo.webp',
        alt: 'Kamerové systémy Avigilon',
    },
    {
        showing: true,
        id: 6,
        img: 'comelit_logo.webp',
        alt: 'Video a audio vrátniky Comelit',
    },
    {
        showing: false,
        id: 7,
        img: 'commax_logo.webp',
        alt: 'Video a audio vrátniky Commax',
    },
])

const selectedImage = ref(1)
const executed = ref(false)
const interval = ref(null)

const nextImage = () => {
    if (selectedImage.value === 7) selectedImage.value = 0
    selectedImage.value++
}

const runSlide = () => {
    const position = document.querySelector('.brands-img-wraper')
    const bottomPosition = window.pageYOffset + window.innerHeight

    if (bottomPosition >= position.offsetTop && executed.value === false) {
        executed.value = true
        nextImage()
        interval.value = setInterval(() => {
            nextImage()
        }, 4500)
    }

    if (bottomPosition <= position.offsetTop && executed.value === true) {
        executed.value = false
        clearInterval(interval.value)
    }
}

onMounted(() => {
    runSlide()
    window.addEventListener('scroll', runSlide)
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', runSlide)
    clearInterval(interval.value)
})
</script>

<template>
    <div class="wraper" v-for="item in brands" :key="item.id" mode="out-in">
        <Transition name="slide" mode="out-in">
            <img
                class="image"
                v-if="item.id === selectedImage"
                :src="`/img/${item.img}`"
                :alt="item.alt"
            />
        </Transition>
    </div>
</template>

<style lang="scss" scoped>
.wraper {
    position: absolute;
    top: 50%;
    left: 50%;

    margin-top: -101.85px;
    margin-left: -250px;
}

.image {
    filter: drop-shadow(0 0 3px rgba(#000, 0.4));
    width: 400px;
    height: auto;
}

.slide-enter-active {
    transition: transform 4500ms linear, opacity 2s ease-in-out;
}

.slide-leave-active {
    transition: transform 4500ms linear, opacity 5s ease-in-out;
}

.slide-enter-from {
    transform: translateX(160%);
    opacity: 0;
}
.slide-leave-to {
    transform: translateX(-160%);
    opacity: 0;
}

// Media queries

@media (max-width: 795px) {
    .image {
        width: 250px;
    }

    .wraper {
        margin-top: -53.2335px;
        margin-left: -125px;
    }
}
</style>
