<template>
    <div
        class="box"
        :class="{ align: !show, answer: show }"
        @click="show = !show"
    >
        <div>
            <slot name="header"></slot>
        </div>

        <div>
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const show = ref(false)
</script>

<style lang="scss" scoped>
@import '../../../sass/main/variables';

.box {
    font-size: 0.9em;
    min-height: 180px;
    position: relative;

    display: flex;
    flex-direction: column;

    background-color: $master-color-noalpha;
    color: white;
    cursor: pointer;

    padding: 1em;

    border-radius: 0.5rem;
    box-shadow: 0 3px 10px rgba(#000000, 0.3);
    &:hover {
        box-shadow: 0 3px 13px rgba(#000000, 0.5);
    }

    transition: box-shadow 200ms ease-in-out;

    div:first-child {
        transition: all 300ms ease-in-out;
        position: relative;
    }

    div:last-child {
        position: absolute;
        z-index: -1;

        font-size: 1.8em;
        padding: 0.8em 1em 0 1em;

        transition: all 200ms ease-in;
        transform: translateY(50px);
        opacity: 0;
    }
}

.align {
    justify-content: center;
    align-items: center;

    div:first-child {
        font-size: 2em;
    }

    &:hover {
        div:first-child {
            transform: scale(1.02);
        }
    }
}

.answer {
    justify-content: center;
    align-items: center;

    div:first-child {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0.6rem;
        color: lighten($master-color-noalpha, 25%);
        font-size: 1.4em;
    }

    div:last-child {
        position: relative;
        z-index: unset;
        display: block;
        transform: translateY(0);
        opacity: 1;
        font-size: 1.8em;
        margin: 0;
        padding: 0.8em 1em 0 1em;
        text-align: center;
    }

    &:hover {
        div:first-child {
            transform: scale(1);
        }
    }
}
</style>
